<template>
  <div class="container">
    <header-back name='银行卡'></header-back>
    <section class="top44">
      <div
        class="bank top16"
        v-if="banks.length > 0"
      >

        <div
          class="bank-list"
          v-for="(item,index) in banks"
          :key='index'
          @click="onBank(item)"
        >
          <van-cell
            value=""
            :border="false"
          >
            <div slot="title">
              <van-image
                radius='2px'
                :src="item.img"
              />

              <!-- <div class="van-cell-text">{{item.bankName}}</div> -->
            </div>
          </van-cell>
          <div class="bank-num">

            <div
              class="bank-num-c"
              v-for="(item,index) in 3"
              :key='index'
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>{{item.number}}</div>

          </div>
        </div>

      </div>
      <!-- 银行卡只能添加一张 -->
      <div
        class="add-bank"
        v-if="banks.length === 0"
      >
        <van-cell
          value=""
          icon="plus"
          @click='onAddBank'
        >
          <div slot="title">
            <div class="van-cell-text">添加银行卡</div>
          </div>
        </van-cell>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";
import API from "../../api/http";
import page from "../../utils/data.js";
export default {
  components: { HeaderBack },
  data() {
    return {
      banks: [
        // {
        //   id: "1",
        //   lable: "招商",
        //   value: "123456776777",
        //   type: "储蓄卡",
        //   img: require("../../assets/image/home/8.png"),
        //   str: "6777",
        // },
        // {
        //   id: "2",
        //   lable: "招商",
        //   value: "123456776777",
        //   type: "储蓄卡",
        //   img: require("../../assets/image/home/8.png"),
        //   str: "6777",
        // },
      ],
      cardId: "",
    };
  },
  created() {
    this.getInit();
  },
  methods: {
    getInit() {
      API.getUserBankList().then((res) => {
        let result = res.data;
        this.banks = result.list.map((item) => {
          let len = item.bankNumber.length;
          return {
            ...item,
            number: item.bankNumber.substring(len - 4),
            img: page.bankMap[item.bankName],
          };
        });
      });
    },
    onBank(item) {
      // this.$router.replace({
      //   path: "/withdraw",
      //   query: {
      //     cardId: item.id,
      //     number: item.bankNumber,
      //   },
      // });
      let data = {
        cardId: item.id,
        number: item.bankNumber,
      };
      utils.setStore("bank", data);
      history.go(-1);
    },
    onAddBank() {
      this.$router.push("/addBank");
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 0 15px;
  box-sizing: border-box;
}

.add-bank,
.bank {
  width: 100%;
}

.bank-list {
  display: block;

  background: linear-gradient(90deg, #f67743 0%, #f75d22 100%);

  border-radius: 5px;
  margin-bottom: 24px;
}

.bank-list .van-image {
  vertical-align: middle;
}

.bank-list .van-cell-text {
  display: inline-block;
  margin-left: 10px;
  color: #fff;
}

.bank-list .van-cell {
  background-color: transparent;
}

.bank-list .van-cell .van-cell__title .van-cell__label {
  margin-left: 30px;
  color: #fff;
  font-size: 12px;
}

.bank-num {
  display: flex;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 26px;
  padding: 16px;
  box-sizing: border-box;
}

.bank-num > div {
  flex: 1;
  text-align: center;
  box-sizing: border-box;
}

.bank-num-c > div {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  margin-left: 6px;
  margin-bottom: 8px;
}
.add-bank {
  margin-top: 30px;
  .van-cell {
    border-radius: 6px;
    padding: 16px;
  }
}
</style>
